// exception-cities
export default [
  {
    title: "Washington, D.C.",
    alias: "Washington",
  },
  {
    title: "New Century, KS",
    alias: "New Century",
  },
];